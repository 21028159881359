body {
    margin: 0;
    font-family: -apple-system, "Avenir", "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #f9fafb;
    display: flex;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

a {
    color: #333996;
    text-decoration: none;
}

#home-page {
    padding-top: 50px;
    padding-bottom: 50px;
    display: flex;
    flex-direction: row;
    background-color: #f9fafb;
    min-height: 100vh;
}

.navbar {
    flex-basis: 400px;
    position: sticky;
    top: 50px;
    padding-left: 75px;
    padding-right: 25px;
    max-width:320px;
    min-width: 320px;
    overflow: scroll;
    height: 90vh;
}

.navbar-image {
    width: 80%;
    margin-bottom: 20px;
    border-radius: 6px;
    display: inline-block;
    box-shadow: 0px 0px 3px 0.2px #798397;
    max-width:200px;
}

.navbar-name {
    font-size: 30px;
    font-weight: 600;
    color: #666666;
    text-align: left;
}

.navbar-email {
    font-size: 15px;
    font-weight: 600;
    color: #666666;
    text-align: left;
}

.social-links {
    padding-top: 0px;
    text-align: left;
}

.personal-links {
    padding-top: 10px;
    text-align: left;
}

.text-button {
    border-radius: 4px;
    background-color: #edebeb;
    border-width: 1.3px;
    border-style: transparent;
    padding-left: 7px;
    padding-right: 7px;
    padding-top: 1px;
    padding-bottom: 1px;
    margin-right: 10px;
    font-size: 13px;
    font-weight: bold;
    color: #444343;
    display: inline-block;
    user-select: none;
    vertical-align: 55%;
    transition: all 0.2s ease-in-out;
}

.text-button:hover {
    box-shadow: 1px 1px 1px 0.0px black;
}

.text-section {
    padding: 0px 0px 0px 0px;
    font-size: 15px;
    font-weight: 400;
    color: #666666
}

.external-link {
    height: 30px;
    width: 30px;
    border-left-width:5px;
    border-left-width: 0px;
    border-style: solid;
    border-color: transparent;
    display: inline-block;
    transition: all 0.2s ease-in-out;
}

.note-tag {
    border-radius: 7px;
    border-width: 1.3px;
    border-style: transparent;
    padding-left: 5px;
    padding-right: 5px;
    margin-right: 10px;
    margin-left: 6px;
    font-weight: bold;
    color: #f11111;
    display: inline-block;
    user-select: none;
    transition: all 0.2s ease-in-out;
}

.github-link{
    content: url("https://raw.githubusercontent.com/seyuboglu/sabri-site/master/res/github-grey.png") url("https://raw.githubusercontent.com/seyuboglu/sabri-site/master/res/github-green.png");
    background: url("https://raw.githubusercontent.com/seyuboglu/sabri-site/master/res/github-grey.png") no-repeat;
    background-size: contain;
    margin-left: 5px;
    margin-right: 5px;
    transition: all 0.2s ease-in-out;
}
.github-link:hover {
    background: url("https://raw.githubusercontent.com/seyuboglu/sabri-site/master/res/github-green.png") no-repeat;
    background-size: contain;
}
.linkedin-link{
    content: url("https://raw.githubusercontent.com/seyuboglu/sabri-site/master/res/linkedin-grey.png") url("https://raw.githubusercontent.com/seyuboglu/sabri-site/master/res/linkedin-green.png");
    background: url("https://raw.githubusercontent.com/seyuboglu/sabri-site/master/res/linkedin-grey.png") no-repeat;
    background-size: contain;
    margin-left: 5px;
    margin-right: 5px;
    transition: all 0.2s ease-in-out;

}
.linkedin-link:hover {
    background: url("https://raw.githubusercontent.com/seyuboglu/sabri-site/master/res/linkedin-blue.png") no-repeat;
    background-size: contain;
}
.twitter-link{
    content: url("https://raw.githubusercontent.com/seyuboglu/sabri-site/master/res/twitter-grey.png") url("https://raw.githubusercontent.com/seyuboglu/sabri-site/master/res/twitter-green.png");
    background: url("https://raw.githubusercontent.com/seyuboglu/sabri-site/master/res/twitter-grey.png") no-repeat;
    background-size: contain;
    margin-left: 5px;
    margin-right: 5px;
    transition: all 0.2s ease-in-out;
}
.twitter-link:hover {
    background: url("https://raw.githubusercontent.com/seyuboglu/sabri-site/master/res/twitter-blue.png") no-repeat;
    background-size: contain;

}

.paper-link{
    background: url("https://raw.githubusercontent.com/seyuboglu/sabri-site/master/res/paper.png") no-repeat;
    background-size: contain; 
}
.paper-link:hover{
    background: url("https://raw.githubusercontent.com/seyuboglu/sabri-site/master/res/paper-yellow.png") no-repeat;
    background-size: contain; 
}

.section-heading {
    display: flex;
    font-size: 32px;
    font-weight: 600;
    color: #666666;
    margin-top:20px;
    padding: 0px;
    font-style: italic;
}


.concept-card {
    display: flex;
    flex-direction: row-reverse;
    margin-right: 5%;
    margin-top:15px;
    box-shadow: 0.5px 0.5px 2px 0.2px #798397;
    border-radius: 4px;
    border-width: 0px;
    border-style: none;
    border-color: #b3b3b3;
    padding: 1.2%;
    background-color: white;

}

.concept-card:first-of-type {
    margin-top:5px;
}

.banner-image-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-left: auto;
}

.banner-image {
    max-height: 100px;
    max-width: 250px;
    object-fit: contain;
}

.card-preview {
    width:50%;
    background-color: white;
    color: #42516e;
    border-radius: 0;
    border-width: 0px 0px 0px 0px;
    border-color: #cacfd6;
    padding-left: 30px;
    border-style: solid;
    display: flex;
    flex-direction: column;
    position: relative;
}

.card-preview a {
    color: #333996;
    font-style: italic;
}

.card-preview-image {
    width: 100%;
    margin: auto;
    border-radius: 10px;
}

.card-preview-objectives {
    margin-top: 20px;
}

.card-body {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.card-body-title {
    font-size: 20px;
    font-weight: 600;
    color: #666666;
    transition: all 0.2s ease-in-out;

}
.software-card-body-title {
    font-size: 20px;
    font-weight: 600;
    color: #666666;
    transition: all 0.2s ease-in-out;
    font-family: monospace;

}
.card-body-title:hover{
    color: #66c2a5
}
.card-body-authors {
    padding:5px 0px 0px 0px;;
    font-size: 15px;
    font-weight: 400;
    color: #666666
}
.card-body-venue {
    padding: 0px 0px 0px 0px;
    font-size: 15px;
    font-weight: 400;
    color: #666666
}

.card-body-links {
    padding: 7px 0px 1px 0px;
}

.card-body-abstract {
    padding: 0px 0px 0px 0px;
    font-size: 15px;
    font-weight: 400;
    color: #666666;
    /* display: flex; */
    /* flex-direction: row; */
}

.card-materials a {
    padding: 8px 8px 5px 5px;
    margin: 12px 30px 0px 0px;
    text-decoration: none;
    color: #42516e;
    font-weight: 600;
    font-size: 18px;
    transition: background-color 0.23s, color 0.23s;
    border-style: solid;
    border-width: 0px 0px 2px 0px;
    border-radius: 5px 5px 0px 0px;
}

.card-materials a:hover {
    background-color: #EFEFEF;
    cursor: pointer;
}

.card-materials a:active {
    background-color: #DDDDDD;
}

.card-materials-title {
    font-size: 20px;
    font-weight: 800;
    margin-top: 30px;
    color: #42516e
}

.card-materials-notes {
    display: flex;
    flex-direction: column;
}

.card-materials-notes a {
    border-color: #333996;
}

.card-materials-notes a:hover {
    color: #333996;
}

.card-materials-notes a:active {
    color: #333996;
}

.card-materials-assignments {
    display: flex;
    flex-direction: column;
}

.card-materials-assignments a {
    border-color: #333996;
}

.card-materials-assignments a:hover {
    color: #333996;
}

.card-materials-assignments a:active {
    color: #333996;
}

.footer {
    margin-top: 100px;
    width: 100%;
    text-align: center;
    font-style: italic;
    color: #42516e;
}

/* Uncommented and updated for two-column software cards layout */
.horizontal-section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    margin-right: 5%;
}

/* Add specific styling for software cards in the grid */
.horizontal-section .concept-card {
    flex-direction: row;
    margin: 5px 0;
    height: 100%;
    padding: 15px;
    box-sizing: border-box;
}

.horizontal-section .card-body {
    width: 65%;
    display: flex;
    flex-direction: column;
}

.horizontal-section .banner-image-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-left: auto;
}

.horizontal-section .banner-image {
    max-height: 100px;
    max-width: 250px;
    object-fit: contain;
}

.horizontal-section .card-body-links {
    padding-top: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 5px;
}

.horizontal-section .card-body-abstract {
    padding-top: 5px;
}

.horizontal-section a {
    display: inline-block;
}

/* Add responsive behavior for smaller screens */
@media screen and (max-width: 768px) {
    .horizontal-section {
        grid-template-columns: 1fr;
    }
    
    .horizontal-section .concept-card {
        flex-direction: column;
    }
    
    .horizontal-section .banner-image {
        width: auto;
        max-width: 50%;
        margin-bottom: 15px;
        margin-right: 0;
    }
    
    .horizontal-section .card-body {
        width: 100%;
        padding-left: 0;
    }
}

.notes-wrapper {
    padding: 100px;
    color: #42516e
}

.notes-wrapper img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 75%;
}

.notes-wrapper h1 {
    color: #333996;
}

.notes-wrapper h2 {
    color: #333996;
}

.notes-wrapper h3 {
    color: #42516e;
}

.notes-wrapper pre {
    padding: 10px;
    background-color: #eef0f8;
    font-weight: 600;
    border-radius: 5px;
}

.notes-wrapper blockquote {
    font-style: italic;
    font-weight: 600;
    margin: 0px;
    padding: 1px 30px;
    background-color: #efefef;
    border-width: 0px 0px 0px 2px;
    border-style: solid;
    border-color: #42516e;
}

.command-line-button {
    font-family: IBMPlexMono,SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
    background-color: rgb(97, 97, 97);
    color: white;
    font-weight: 600;
    border-radius: 3px;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 4px;
    padding-bottom: 4px;
    margin-left: 8px;
    margin-top: 12px;
    width: fit-content;
}

.command-line-button:hover{
    box-shadow: 1px 1px 1px 0.0px black;
}

@media screen and (max-width: 2000px) {
    

    .card-preview {
        display: none;
    }
}

@media screen and (max-width: 1400px) {
    #home-page {
        flex-direction: column;
    }

    .navbar {
        /* content: ""; */
        width: 100%;
        display:grid;
        grid-template-columns: auto auto 1fr;
        gap: 20px;
        clear: both;
        max-width: 85%; 
        padding-left: 5%;
        height: 256px;
    }

    .navbar-image {
        /* width: 25%; */
    }

    .navbar-info {
        /* padding-left: 5%; */
    }

    .navbar-about {
        overflow-y: scroll;
        
    }

    .section-heading {
        margin-left: 5%;        
    }

    .concept-card {
        margin-left: 5%;
    }
    
    /* Ensure consistent margins for software cards */
    .horizontal-section {
        margin-left: 0;
    }
    
    .horizontal-section .concept-card {
        margin-left: 5%;
    }

    .google-scholar {
        margin-left: 5%;
    }
}



@media screen and (max-width: 1100px) {
    #home-page {
        flex-direction: column;
    }
    .navbar {
        content: "";
        width: 85%;
        display: table;
        clear: both;
        position:static;
        max-width: 85%; 
        height:20vh;
        padding-left: 5%;
    }

    .navbar-image {
        float: left;
        width: 25%;
    }

    .navbar-info {
        float: left;
        width: 70%;
        padding-left: 5%;
    }

    .navbar-about {
        float: left;
        width:100%;
    }

    .section-heading {
        margin-left: 5%;        
    }

    .concept-card {
        margin-left: 5%;
    }

    .google-scholar {
        margin-left: 5%;
    }
}


@media screen and (max-width: 400px) {

    .navbar-image {
        display: none;
    }

    .navbar-info {
        width: 100%;
        float: left;
        padding-left: 5%;

    }

    .navbar-about {
        float: left;
        width:100%;
        padding-left: 5%;

    }

    .navbar {
        padding: 0px;
    }
}

